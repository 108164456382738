/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}


.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert {

  padding: 6px 19px !important;
  border-radius: 50px !important;
}

.map_search_box{
  height: 300px;
}

.tbl_content{
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.tbl_title{
  color: black;
  font-size: 18px;
  font-weight: bold;
  background: #d3d3d3;

}
.tbl_head_content{
  color: black;
  font-size: 18px;
  font-weight: bold;
  background: gray;
}
table tr {
  height: 45px;
}
table td {
  padding: 5px;
}
.tbl_h3_content {
  text-align: center;
  color: black;
  font-size: 20px;
  font-weight: bold;
 }
.tbl_total_content {
  color: black;
  font-size: 20px;
  font-weight: bold;
  background: whitesmoke;
 }
.tbl_total_content_contact{
  color: black;
  font-size: 15px;
  font-weight: bold;
 }
