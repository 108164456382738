@font-face {
  font-family: 'Almarai-Regular';
  src: url('Almarai-Regular.eot');
  src: url('Almarai-Regular.eot?#iefix') format('embedded-opentype'),
       url('Almarai-Regular.svg#Almarai-Bold') format('svg'),
       url('Almarai-Regular.ttf') format('truetype'),
       url('Almarai-Regular.woff') format('woff'),
       url('Almarai-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


div,p,h1,h2,h3,h4,h5,select,input,textarea,.banner-style-one .inner-column .sub-title,
.main-header .header-top .inner-container .top-left .list-style-one li,.info-btn, .theme-btn,.widget-title,.title,.sub-title
,.inner-box span,.title2,.mb-0,.counter-title
{
    font-family:  'Almarai-Regular', sans-serif !important;
    text-shadow: none !important;
    font-weight: normal !important;
  letter-spacing: 0 !important;
}
